import { noNullQuerySelector } from "../src/utils/dom";

document.addEventListener("DOMContentLoaded", () => {
  ["subjects", "courses"].forEach((item) => {
    const link = noNullQuerySelector<HTMLAnchorElement>(`#${item}-link`);
    const dropdown = noNullQuerySelector(`#${item}-dropdown`);

    // Hover behavior to open the dropdown
    link.addEventListener("mouseenter", () => {
      openDropdown();
    });

    dropdown.addEventListener("mouseenter", () => {
      openDropdown();
    });

    // Hover behavior to close the dropdown
    link.addEventListener("mouseleave", () => {
      setTimeout(() => {
        if (!dropdown.matches(":hover")) closeDropdown();
      }, 200); // Slight delay for smoother transitions
    });

    dropdown.addEventListener("mouseleave", () => {
      setTimeout(() => {
        if (!link.matches(":hover")) closeDropdown();
      }, 200);
    });

    // Click behavior to open the link in a new tab
    link.addEventListener("click", (event) => {
      event.preventDefault(); // Prevent default dropdown behavior
      const href = link.getAttribute("href");
      if (href) {
        window.open(href, "_blank"); // Open link in a new tab
      }
    });

    function openDropdown() {
      link.setAttribute("aria-expanded", "true");
      dropdown.classList.add("show");
    }

    function closeDropdown() {
      link.setAttribute("aria-expanded", "false");
      dropdown.classList.remove("show");
    }
  });
});
